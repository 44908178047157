/**
 * 題目类型
 */

export enum questionType {
  SINGLE_CHOICE = '单选',
  MULTIPLE_CHOICE = '多选',
  TEXT_QUESTION = '文本',
  IMG_TEXT_QUESTION = '图文'
}

export type questionTypeText = '单选' | '多选' | '文本' | '图文'
